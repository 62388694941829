<template>
  <div :class="{ 'form-wrap': flashcards, flashcards: flashcards, 'flashcards-study': flashcardsStudy }">
    <div class="header" :class="{ 'back-action': backButton }">
      <div v-if="backButton" class="back-icon" @click="$emit('prev-step')">
        <IconArrow class="icon-arrow" />
      </div>
      <h1 v-if="!flashcards" class="h4">
        Log In to Your Profile
      </h1>
      <h1 v-else-if="!flashcardsStudy" class="h5">
        Log in to see your decks.
      </h1>
      <div v-else class="arrow-flashcards-study">
        <ArrowLeft v-if="hideSocials" @click="formSwapping" />
        <h1 class="h4" :class=" { 'with-arrow': hideSocials }">
          Log In to Your Profile
        </h1>
      </div>
    </div>
    <div v-if="!hideSocials" class="social-login-buttons" :class="{ 'flashcards-study': flashcardsStudy }">
      <div class="google-btn">
        <GoogleLoginButton
          :form-type="formType"
          :keep-me-logged-in="!!keepMeLoggedIn"
          data-event="google.click"
          :terms-agreed="flashcards"
          data-async-event
          data-direct-send
          :resources="resources"
          flashcards
        />
      </div>
      <div>
        <FacebookLoginButton
          :form-type="formType"
          :keep-me-logged-in="!!keepMeLoggedIn"
          data-event="facebook.click"
          :terms-agreed="flashcards"
          data-async-event
          data-direct-send
          :resources="resources"
          flashcards
        />
      </div>
      <div>
        <LinkedinLoginButton
          :form-type="formType"
          :keep-me-logged-in="!!keepMeLoggedIn"
          data-event="linkedin.click"
          :terms-agreed="flashcards"
          data-async-event
          data-direct-send
          :resources="resources"
        />
      </div>
    </div>
    <div v-if="!hideSocials" class="divider" />
    <form
      v-if="showHideForm || showLoginForm"
      class="login-form"
      method="post"
      @submit.prevent="loginUser"
    >
      <div class="input-group">
        <input
          id="email"
          v-model="login.email"
          type="email"
          name="email"
          autocomplete="username"
          placeholder="Email"
          :class="{ 'invalid-field': emailAlert }"
          data-event="email.click"
          data-direct-send
          @input="emailAlert = ''"
        >
        <small v-if="emailAlert" class="alert">{{ emailAlert }}</small>
      </div>
      <div class="input-group">
        <input
          id="password"
          v-model="login.password"
          :type="visiblePassword ? 'text' : 'password'"
          name="password"
          autocomplete="current-password"
          placeholder="Password"
          :class="{ password: !visiblePassword, 'invalid-field': passwordAlert }"
          data-event="password.click"
          data-direct-send
          @input="passwordAlert = ''"
        >
        <Component
          :is="visiblePassword ? 'IconEye' : 'IconEyeClosed'"
          class="icon-eye"
          @click="visiblePassword = !visiblePassword"
        />
        <small v-if="passwordAlert" class="alert">{{ passwordAlert }}</small>
      </div>
      <template v-if="banned">
        <div class="banned-alert">
          <IconWarning /> {{ banned }}
        </div>
        <button type="button" class="submit btn-lg btn-primary" @click="$router.push('/forgotten-password/')">
          Reset Password
        </button>
        <p class="or-sign-up">
          If you think there is a problem with your account, please <nuxt-link to="/contact-us/">
            contact us
          </nuxt-link>.
        </p>
      </template>
      <template v-else>
        <div class="input-group links">
          <div class="vue-checkbox">
            <input
              id="keeplogin" v-model="keepMeLoggedIn" type="checkbox"
              class="custom-checkbox reverse-colors"
              data-event="rememberme.click"
              data-direct-send
            >
            <label for="keeplogin">Remember me</label>
          </div>
          <div>
            <nuxt-link v-if="!flashcards" to="/forgotten-password/" data-event="forgotpswd.click">
              Forgot your password?
            </nuxt-link>
            <a v-else data-event="forgotpswd.click" @click="$emit('forgotten-password')">
              Forgot password?
            </a>
          </div>
        </div>
        <button
          class="submit btn-lg btn-primary"
          data-event="login.click"
          data-async-event="login.click"
          data-direct-send
        >
          Log In
        </button>
        <p v-if="!flashcardsStudy" class="or-sign-up">
          Don’t have an account?
          <span v-if="resources" @click="$emit('signup')">
            Sign Up
          </span>
          <nuxt-link v-else-if="!flashcards" to="/signup/">
            Sign Up
          </nuxt-link>
          <a v-else @click="$emit('signup')">
            Sign Up
          </a>
        </p>
      </template>
    </form>
    <div v-else class="action-btn-terms">
      <div class="action-btn">
        <button @click="formSwapping('show')">
          <IconMail class="mail-icon" /> Continue with email
        </button>
      </div>
      <p class="terms">
        By continuing, you agree to {{ $config.platformName }}’s
        <a target="_blank" :href="`${$config.siteUrl}/terms-of-use/`">Terms of Use</a>
        and
        <a target="_blank" :href="`${$config.siteUrl}/privacy-policy/`">Privacy Policy</a>
      </p>
    </div>
  </div>
</template>

<script>

import IconArrow from '@itbpbg/lms-components/src/assets/svg/icon-arrow-back.svg?vue-component';
import IconMail from '@itbpbg/lms-components/src/assets/svg/icon-mail.svg?vue-component';
import ArrowLeft from '../assets/svg/flashcards-left-arrow.svg?vue-component';
import GraduatesWork from './GraduatesWork';
import FacebookLoginButton from './FacebookLoginButton';
import GoogleLoginButton from './GoogleLoginButton';
import LinkedinLoginButton from './LinkedinLoginButton';
import IconEye from '../assets/svg/eye.svg?vue-component';
import IconEyeClosed from '../assets/svg/eye-closed.svg?vue-component';
import IconWarning from '../assets/svg/warning-gray.svg?vue-component';

export default {
  components: {
    GraduatesWork,
    FacebookLoginButton,
    GoogleLoginButton,
    LinkedinLoginButton,
    IconEye,
    IconEyeClosed,
    IconArrow,
    IconMail,
    ArrowLeft,
    IconWarning,
  },
  props: {
    type: { type: String, default: '' },
    hideSocials: Boolean,
    backButton: Boolean,
    resources: Boolean,
    flashcards: Boolean,
    flashcardsStudy: Boolean,
    showForm: Boolean,
  },
  data() {
    return {
      visiblePassword: false,
      login: {
        email: '',
        password: '',
      },
      formType: this.type || this.$route.name,
      keepMeLoggedIn: false,
      emailAlert: '',
      passwordAlert: '',
      showHideForm: !this.flashcardsStudy,
      showLoginForm: this.showForm,
      banned: '',
    };
  },
  mounted() {
    if (this.$route.query.error && this.$route.query.error === 'business') {
      this.$store.commit('auth/setLoginAlert', true);
      const query = { ...this.$route.query };
      delete query.error;
      this.$router.replace({ query });
    }
    this.url = this.$route.params.url;
  },
  methods: {
    async loginUser() {
      if (!this.login.email) {
        this.emailAlert = 'Required field';
        this.$tracking.sendAsyncEvent('login.click', { status: 'fail', error: 'Please fill in email!' });
        return;
      }

      if (!this.login.password) {
        this.passwordAlert = 'Required field';
        this.$tracking.sendAsyncEvent('login.click', { status: 'fail', error: 'Please fill in password!' });
        return;
      }

      const login = await this.$store.dispatch('auth/login', {
        email: this.login.email,
        password: this.login.password,
        keepMeLoggedIn: this.keepMeLoggedIn,
        resources: this.resources,
        course: this.$route.query.course,
      });

      if (login) {
        if (login.status !== 'error') {
          if (login.firstLogin) {
            this.$router.push('/forgotten-password/');
          } else if (this.resources) {
            await this.$tracking.sendAsyncEvent('login.click', { status: 'success' });
            this.$emit('continueToExamResult');
          } else {
            await this.$tracking.sendAsyncEvent('login.click', { status: 'success' });

            if (!this.flashcards) {
              if (this.$route.query.course) {
                if (!login.courseEnrolled.enrolled && login.courseEnrolled.reason === 'already-enrolled') {
                  this.$redirectAfterLogin('/?msg=course-already-enrolled');
                } else if (this.$store.getters['auth/user'].simpleLms) {
                  this.$redirectAfterLogin('/');
                } else {
                  this.$redirectAfterLogin('/courses/?tab=my-courses');
                }
              } else if (this.$store.getters['auth/hasAnyAdminLegacyPermissions']) {
                this.$redirectAfterLogin('/admin-legacy/');
              } else if (this.$store.getters['auth/hasAnyAdminPermissions']) {
                this.$redirectAfterLogin('/admin/');
              } else if (this.$store.getters['auth/user'].role === 'instructor') {
                this.$redirectAfterLogin('/instructor/courses/');
              } else {
                this.$redirectAfterLogin('/my-learning-path/');
              }
            }
          }
          this.$root.$emit('login');
        } else if (login.status === 'error') {
          this.emailAlert = '';
          this.passwordAlert = '';

          if (login.error === 'incorrectPassword') {
            this.passwordAlert = login.message;
          } else if (login.error === 'TooManyFailedLoginAttempts') {
            this.banned = login.message;
          } else if (login.status === 'error') {
            this.emailAlert = login.message;
          }

          this.$tracking.sendAsyncEvent('login.click', { status: 'fail', error: login.message });
        }
      }
    },
    formSwapping(showHide) {
      if (showHide === 'show') {
        this.showHideForm = true;
      } else {
        this.showHideForm = false;
        this.showLoginForm = false;
      }

      this.$emit('email-choice');
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrap {
  h1 {
    margin-top: 0;
    margin-bottom: var(--spacing-24);
    text-align: center;
  }

  .divider {
    position: relative;
    padding: var(--spacing-36) 0;

    &:before,
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      background-color: var(--main-light);
      color: var(--main-dark-opacity-48);
      content: 'or';
      font-size: 16px;
      padding: var(--spacing-12);
      z-index: 2;
    }

    &:after {
      border-top: 1px solid var(--main-dark-opacity-18);
      content: '';
      width: 100%;
    }
  }

  .input-group {
    position: relative;
    padding-bottom: var(--spacing-12);

    &.links {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
    }

    input.invalid-field {
      border-color: var(--main-red);
    }

    .alert {
      color: var(--main-red);
    }

    .icon-eye {
      cursor: pointer;
      position: absolute;
      right: var(--spacing-16);
      top: var(--spacing-14);
    }
  }

  .banned-alert {
    border: 1px solid var(--main-red-light-3);
    border-radius: 5px;
    color: var(--main-red-shade-1);
    background: var(--main-red-light-2);
    font-size: 14px;
    line-height: 18px;
    padding: 12px;
    display: flex;
    align-items: flex-start;
    margin-top: 12px;
    margin-bottom: 24px;

    svg {
      flex: 0 0 auto;
      margin-right: 8px;

      path:first-child {
        fill: var(--main-red);
      }
    }

    & + button {
      margin-top: 0;
      margin-bottom: var(--spacing-36);
    }
  }

  .submit {
    margin: var(--spacing-12) auto var(--spacing-36);
    width: 100%;
    font-weight: 600;
    font-size: 18px;
  }

  .or-sign-up {
    color: var(--main-dark-opacity-48);
    text-align: center;
    margin: 0;
    line-height: 150%;

    span {
      color: var(--link-color);
      cursor: pointer;
    }
  }

  @include bp($bp-mobile) {
    padding: var(--spacing-24);
    max-width: 100%;
  }
}

.header {
  &.back-action {
    position: relative;
    padding-left: var(--spacing-8);

    .back-icon {
      position: absolute;
      left: -6px;
      top: 50%;
      padding: var(--spacing-6);
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}

.social-login-buttons {
  display: block;
  overflow: hidden;

  > div {
    border-radius: 5px;
    border: 1px solid var(--main-dark-opacity-12);
    float: left;
    height: 52px;
    width: 52px;

    &:hover {
      background-color: var(--main-light-shade-1);
      border: 1px solid var(--main-dark-opacity-12);
    }

    &:not(:last-of-type) {
      margin-right: 12px;
    }

    &.google-btn {
      width: calc(100% - 128px);
    }
  }

  &.flashcards-study {
    display: flex;
    overflow: unset;
  }
}

@include bp($bp-mobile) {
  .form-wrap {
    h1 {
      margin-bottom: var(--spacing-36);
    }

    .submit {
      margin: var(--spacing-18) auto var(--spacing-30);
    }
  }
}

.flashcards {
  margin: var(--spacing-50) auto var(--spacing-30) auto;
  max-width: 310px;
  width: 100%;

  .submit {
    margin-bottom: var(--spacing-20);
  }

  &.flashcards-study {
    width: 332px;
    margin: 0;

    .login-form {
      .submit {
        height: 56px;
        margin: 0;
      }

      .links {
        padding-bottom: var(--spacing-16);

        label,
        a {
          font-weight: 500;
        }

        label {
          color: rgba(0, 12, 31, 0.48);
        }
      }
    }

    .arrow-flashcards-study {
      display: flex;
      align-items: center;
      margin-bottom: var(--spacing-20);

      h1 {
        white-space: nowrap;
        margin: 0 37px 0 var(--spacing-50);
      }

      svg {
        width: 8px;
        height: 12px;
        fill: var(--main-dark-light-4);
        cursor: pointer;
      }
    }

    .divider {
      padding: 21px 0;

      &:before {
        padding: var(--spacing-8) var(--spacing-12);
        background-color: var(--main-light);
      }
    }

    .action-btn-terms {
      .action-btn {
        border: 1px solid rgba(0, 12, 31, 0.18);
        border-radius: 5px;

        .mail-icon {
          height: 24px;
        }

        &:not(:last-child) {
          margin-bottom: var(--spacing-12);
        }

        &:hover {
          background-color: var(--main-light-shade-1);
          border: 1px solid var(--main-dark-opacity-12);
        }

        > button {
          border: none;
          background: none;
          padding: 0;
          margin: 0;
          width: 100%;
          font-size: 100%;
          font-family: inherit;
          line-height: normal;
          cursor: pointer;

          > svg {
            width: 24px;
            padding-left: 2px;
            padding-right: 2px;

            > path[stroke] {
              stroke: var(--main-dark-light-3);
            }
          }
        }

        > a,
        a.visible,
        button {
          display: flex;
          align-items: center;
          padding: var(--spacing-14) var(--spacing-44);
          line-height: unset;
          font-size: 18px;

          > svg {
            margin-right: 15px;
            width: 24px;
          }
        }

        .invisible {
          overflow: hidden;
        }
      }

      .terms {
        display: block;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        margin: var(--spacing-24) 0 0;
        color: rgba(0, 12, 31, 0.48);
        line-height: 130%;
      }
    }
  }

  .divider {
    &:before {
      background-color: var(--main-light-shade-1);
    }
  }
}

@include bp($bp-laptop) {
  .flashcards {
    &.flashcards-study {
      width: 310px;

      .arrow-flashcards-study {
        h1 {
          font-size: 21px;
        }
      }
    }
  }
}

@include bp($bp-mobile) {
  .flashcards {
    max-width: 100%;
    margin-top: var(--spacing-32);
    margin-bottom: 0;
    padding: 0 var(--spacing-32);

    &.flashcards-study {
      width: 100%;
      padding: 0;

      .action-btn-terms .action-btn {
        button {
          padding: var(--spacing-14) 0;
          justify-content: center;
        }
      }
    }

    .arrow-flashcards-study.arrow-flashcards-study {
      justify-content: center;

      h1 {
        margin: 0;

        &.with-arrow {
          margin: 0 37px 0 var(--spacing-50);
        }
      }
    }
  }
}

@include bp($bp-phone) {
  .flashcards {
    &.flashcards-study {
      padding: 0;

      .action-btn-terms {
        .terms {
          display: none;
        }

        .action-btn.action-btn {
          margin-bottom: 0;
        }
      }

      .divider {
        padding: var(--spacing-32) 0;
      }

      .arrow-flashcards-study {
        h1 {
          &.with-arrow {
            margin: 0 var(--spacing-40) 0 var(--spacing-30);
          }
        }
      }
    }

    h1 {
      margin-bottom: var(--spacing-16);
    }
  }
}
</style>
